import CloseBtn from "./CloseBtn.js";

export default class {
   constructor(currentHash) {
      this.currentHash = currentHash;

      this.menuBtn = $("body").find(".main-menu-btn");
      this.closeBtn = new CloseBtn("body", e => {
         if (this.currentHash === "main-menu") {
            return this.set("slider");
         }

         this.set("main-menu");
      });

      this.closeBtn.$element.hide();

      this.init();
   }

   init() {
      const page = this;

      this.set(self.location.hash, 0);

      $("a[href^='#!/']").on("click", function(e) {
         const $a = $(this);
         const hash = $(this)[0].hash.replace(/^#!\//, "");

         page.set(hash);

         if (!hash) {
            self.location.href = "#";
         }

         return false;
      });
   }

   set(hash, fade = 500) {
      // get new hash
      hash = hash.replace(/^#!\//, "") || "slider";

      console.log("SETTING PAGE", hash);
      if (hash === "slider") {
         console.log(
            "get color from",
            $("#slider .slide .content"),
            $("#slider .slide .content").css("color")
         );

         let color = "white";
         switch ($("#slider .slide .content").css("color")) {
            case "rgb(0, 0, 0)":
            case "#000000":
            case "black":
               color = "black";
         }

         console.log("go to color", color);

         $("body").addClass(color);
      } else {
         $("body").removeClass();
      }

      console.log("show/hide", this.closeBtn, this.menuBtn);
      switch (hash) {
         case "slider":
            this.closeBtn.$element.fadeOut();
            this.menuBtn.fadeIn();
            break;

         default:
            this.closeBtn.$element.fadeIn();
            this.menuBtn.fadeOut();
            break;
      }

      if (hash === this.currentHash) {
         return;
      }

      // make sure page exists
      let $page = $(`#${hash}`);
      if (!$page.length) {
         return;
      }

      // hide old pages, fade in new one
      $(".slider-btns").fadeOut(fade);
      $(".page:visible").fadeOut(fade);
      $("#slider .content:visible").fadeOut(fade);

      setTimeout(e => {
         this.currentHash = hash;
         $page
            .css({ opacity: 1 })
            .hide()
            .fadeIn();

         if (hash === "slider") {
            $(".slider-btns").show();
         }
      }, fade);
   }
}
