import Page from "./Page.js";
import Slider from "./Slider.js";

const $ = window.jQuery;
const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();

console.log(isMobile ? "Detected mobile" : "Detected desktop");

// don't hide arrows if on mobile
window.moved = isMobile;

const page = new Page("#slider");

// Setup Slider
const $prevBtn = $(".slider-prev-btn");
const $nextBtn = $(".slider-next-btn");

const slider = new Slider($("#slider"), $prevBtn, $nextBtn, {
   isMobile: isMobile
});

// show/hide arrows on desktop
let timeout;

if (!isMobile) {
   console.log("detecting mouse move");

   const $btns = $()
      .add($prevBtn)
      .add($nextBtn)
      .hide();

   $(document)
      .on("mousemove", e => {
         window.moved = true;

         if (page.currentHash !== "slider") return;

         const width = $(window).width();
         const height = $(window).height();
         const center = { x: width / 2, y: height / 2 };
         const mouse = { x: e.pageX, y: e.pageY };
         const distanceFromCenter = Math.sqrt(
            Math.pow(mouse.x - center.x, 2) + Math.pow(mouse.y - center.y, 2)
         );
         const distanceThreshold = Math.abs(distanceFromCenter) / height * 100;

         clearTimeout(timeout);

         if (distanceThreshold < 30) {
            slider.$container.find(".content").fadeIn();
         } else {
            slider.$container.find(".content").fadeOut();
         }

         $btns.fadeIn();

         timeout = setTimeout(() => {
            $btns.fadeOut();
            slider.$container.find(".content").fadeOut();
         }, 5000);
      })
      .on("mouseleave", e => {
         $btns.fadeOut();
         slider.$container.find(".content").fadeOut();
      });
} else {
   const $btns = $()
      .add($prevBtn)
      .add($nextBtn)
      .show();

   $("body").on("touchstart", e => {
      console.log("detected touch");
      window.moved = true;

      if (self.location.hash || window.ignore) return;

      clearTimeout(timeout);

      if (slider.$container.find(".content").is(":visible")) {
         return slider.$container.find(".content").fadeOut();
      }

      slider.$container.find(".content").fadeIn();

      timeout = setTimeout(() => {
         slider.$container.find(".content").fadeOut();
      }, 5000);
   });

   $btns.on("touchstart", e => {
      window.ignore = true;
      requestAnimationFrame(e => (window.ignore = false));
   });
}
