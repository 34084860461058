import CloseBtn from "./CloseBtn.js";

const fadeDuration = 1000;
const contentHideDelay = 5000;

const ua = navigator.userAgent.toLowerCase();
const isSafari = ua.indexOf("safari/") > -1 && ua.indexOf("chrome") < 0;

console.log(isSafari ? "is safari" : "is not safari");

export default class {
   constructor($container, $prevBtn, $nextBtn, opts) {
      this.$container = $container;
      this.$prevBtn = $prevBtn;
      this.$nextBtn = $nextBtn;

      this.slides = [];
      this.index = 0;

      this.load().then(() => this.init());
   }

   load() {
      console.log("Loading...");

      return new Promise(resolve => {
         $.getJSON("./data/videos.json", datas => {
            console.log("Got data", datas);

            datas.forEach(data => {
               const $element = $("<div class=slide>").on("click", e =>
                  this.showFullVideo(data.url)
               );

               const number = this.slides.length + 1;
               const total = datas.length;
               // create video
               const video = $(
                  isSafari
                     ? "<video playsinline muted controls=true>"
                     : "<video muted>"
               )
                  .attr({
                     src: `./videos/${data.file}`,
                     type: "video/mp4",
                     preload: number === 1 ? "auto" : "none"
                  })
                  .appendTo($element)[0];

               // create content
               const content = $("<div class=content>")
                  .css({ color: data.color })
                  .append($("<h2>").html(data.title))
                  .append($("<h3>").html(data.subtitle))
                  .append($("<span>").html(`${number}/${total}`))
                  .appendTo($element)[0];

               this.slides.push({
                  data,
                  $element,
                  video,
                  content
               });
            });

            resolve(this.slides);
         });
      });
   }

   init() {
      console.log("Initialising...");

      // remove placeholder html
      this.$container.html("");

      // load current slide
      const index = this.wrapAround(this.index);
      const slide = this.slides[index];

      this.$container.hide().append(slide.$element);

      $(slide.video)
         .one("canplay", e => slide.video.play())
         .one("playing", e => this.intro())
         .one("ended", e => this.next())[0]
         .load();

      $(slide.content).hide();

      // activate arrows
      this.$prevBtn.on("click", e => this.prev());
      this.$nextBtn.on("click", e => this.next());
   }

   wrapAround(index) {
      const max = this.slides.length - 1;
      const min = 0;
      if (index < min) return max;
      if (index > max) return min;
      return index;
   }

   intro() {
      this.$container.fadeIn(500);

      // fade ui color
      $("body")
         .removeClass()
         .addClass(this.slides[this.index].data.color);

      const $btns = $()
         .add(this.$prevBtn)
         .add(this.$nextBtn);

      if (!window.moved)
         setTimeout(
            () =>
               $btns.fadeIn({
                  duration: 500,
                  done: e =>
                     setTimeout(
                        () =>
                           !this.moved &&
                           $btns.fadeOut({
                              duration: 500
                           }),
                        2000
                     )
               }),
            1000
         );
   }

   next() {
      this.setIndex(this.index + 1);
   }

   prev() {
      this.setIndex(this.index - 1);
   }

   setIndex(index) {
      if (this.changingSlides) return;

      this.changingSlides = true;

      const nextIndex = this.wrapAround(index);
      const currIndex = this.index;

      const currSlide = this.slides[currIndex];
      const nextSlide = this.slides[nextIndex];

      console.log("Setting slide", nextSlide);

      let offset = -1;
      if (nextIndex > currIndex) offset = 1;
      if (nextIndex === this.slides.length - 1 && currIndex === 0) offset = -1;
      if (nextIndex === 0 && currIndex === this.slides.length - 1) offset = 1;

      $(nextSlide.video)
         .one("canplay", e => nextSlide.video.play())
         .one("playing", e =>
            requestAnimationFrame(() =>
               this.animateBetweenSlides(currSlide, nextSlide, offset)
            )
         )
         .one("ended", e => this.next())[0]
         .load();

      this.index = nextIndex;
   }

   animateBetweenSlides(from, to, offset) {
      const duration = 250;
      const showTitleDuration = 1000;

      // setup animation
      this.$container.css("transition", `transform ${duration}ms ease-out`);

      // place to element offscreen
      to.$element
         .css({
            transform: `translateX(${offset * 100}vw)`
         })
         .appendTo(this.$container);

      $(to.content).hide();

      requestAnimationFrame(e => {
         // move current slide offscreen
         this.$container.css({
            transform: `translateX(${offset * -100}vw)`
         });

         // hide current slide
         setTimeout(() => {
            const clear = { transform: "", transition: "" };
            from.$element.remove().css(clear);
            to.$element.css(clear);
            this.$container.css(clear);
            this.changingSlides = false;

            // fade ui color
            $("body")
               .removeClass()
               .addClass(to.data.color);
         }, duration);
      });
   }

   showFullVideo(src) {
      const $template = $("#vimeo");
      const $embed = $("<dialog open class=embed>")
         .html($template.html())
         .hide()
         .appendTo("body");

      const $iframe = $embed.find("iframe").hide();

      const closeBtn = new CloseBtn($embed, e => {
         $embed.fadeOut({ done: e => $embed.remove() });
      });

      $iframe
         .on("load", e => {
            $iframe.fadeIn();
         })
         .attr({ src });

      $embed.fadeIn();
   }
}
